// Filter response for requested stations
export function filterResponse(response, body) {
  let filteredResponse = []
  for (let i = 0; i < response.result.length; i++) {
    if (
      response.result[i].station === body.from_loc ||
      response.result[i].station === body.to_loc
    ) {
      filteredResponse.push(response.result[i]);
    }
  }
  return filteredResponse
}

// Group results by rid and check if cancelled
// Return map == rid: {from: {stop info), to: {stop info}, cancelled: bool}
export function groupResponse(filteredResponse, body) {
  const groupedResponse = new Map()
  for (let i = 0; i < filteredResponse.length; i++) {
    const rid = filteredResponse[i].rid;
    const journey = new Map();
    for (let j = 0; j < filteredResponse.length; j++) {
      if (filteredResponse[j].rid === rid) {
        if (filteredResponse[j].station === body.from_loc) {
          journey.set("from", filteredResponse[j]);
        } else if (filteredResponse[j].station === body.to_loc) {
          journey.set("to", filteredResponse[j]);
        }
      }
    }
    journey.set("cancelled", getCancellation(journey));
    groupedResponse.set(rid, journey);
  }
  return groupedResponse
}

// Sort grouped results by depart time
export function sortResponse(groupedResponse) {
  return new Map(
    [...groupedResponse.entries()].sort((a, b) => {
      return (
        new Date(a[1].get("from").sched_depart.Time) -
        new Date(b[1].get("from").sched_depart.Time)
      );
    })
  )
}


// Checked sorted response values for cancellations
// Cancelled == departure actual_depart or destination actual_arrival are not valid
// Not using only `late_canc_reason.Valid` because a service can be cancelled part way through a complete run
// So a cancelled train may still have departed from and arrived at the stations in search query
function getCancellation(value) {
  let cancelled = {};
  const isCancelled = value.get('from').actual_depart.Valid === false || value.get('to').actual_arrival.Valid === false
  if (isCancelled === true) {
    cancelled.bool = isCancelled
    if (value.get('to').late_canc_reason.Int32 !== 0) {
      cancelled.reason = value.get('to').late_canc_reason.Int32
    } else if (value.get('from').late_canc_reason.Int32 !== 0) {
      cancelled.reason = value.get('from').late_canc_reason.Int32
    } else {
      cancelled.reason = 0
    }
  } else {
    cancelled.bool = false
    cancelled.reason = 0
  }
  return cancelled;
}

// Summary of journey delays/cancellations
export function summaryStats(groupedResponse) {
  let delays = [],
  onTime = 0,
  lateNo = 0,
  lateClaimPartial = 0,
  lateClaimFull = 0,
  stats = {};
  
  // Get array of all uncancelled destination delay values
  groupedResponse.forEach((stops) => {
    if (stops.get("cancelled").bool === true) {
      lateClaimFull++
    } else if ( stops.get("to").delay.Valid === true) {
      delays.push(stops.get("to").delay.Int32)
    }
  })

  delays.forEach((delay) => {
    if (delay <= 0) {
      onTime++;
    } else if (delay > 0 && delay < 30) {
      lateNo++;
    } else if (delay >= 30 && delay < 60) {
      lateClaimPartial++;
    } else if (delay >= 60) {
      lateClaimFull++;
    }
  });

  stats.onTime = onTime;
  stats.lateNo = lateNo;
  stats.lateClaimPartial = lateClaimPartial;
  stats.lateClaimFull = lateClaimFull;
  
  return {stats, delays}
}