import axios from "axios";

// Used by stage specific environments to change the backend API URL
const API_BASE = process.env.NODE_ENV !== "production" ? "http://localhost:8000" : window.config.apiURL;

const apiClient = axios.create({
  baseURL: API_BASE,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export function getStations() {
  return apiClient.get("/stations");
}

export function getDemoStations() {
  return axios.get("/stations.json");
}

export function getDemoResponse() {
  return axios.get("/edb.json");
}

export function postSubmit(body) {
    return apiClient.post("/submit", body);
  }

export function getDetails() {
    return apiClient.get("/details");
  }

export function getRuns() {
    return apiClient.get("/runs");
  }
