import React, {useEffect, useState} from 'react';
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Home from './views/Home';
import Results from './views/Results';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

export default function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkmode, setDarkMode] = useState(prefersDarkMode);
  const theme = createTheme({
    palette: {
      mode: darkmode ? 'dark' : 'light',
      primary: {
        main: '#00d1b2',
      },
      secondary: {
        main: '#d1001f',
      },
      success: {
        main: '#00d149',
      },
      info: {
        main: '#0088d1',
      },
    },
  });
  useEffect(() => {
    setDarkMode(prefersDarkMode);
  }, [prefersDarkMode]);
  const handleDarkModeToggle = () => {
    setDarkMode(!darkmode);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* Put everything in a flexible box to ensure sticky footer */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <BrowserRouter>
          <Navbar handleDarkModeToggle={handleDarkModeToggle} darkmode={darkmode}/>
            <Routes>
              <Route path='/'>
                <Route index element={<Home />} />
                <Route path="results" element={<Results />} />
                <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <p>Not found</p>
                    </main>
                  }
                />
              </Route>
            </Routes>
          <Footer/>
        </BrowserRouter>
      </Box>
    </ThemeProvider>
  );
}
