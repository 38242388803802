import React from "react";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import GlobalStyles from '@mui/material/GlobalStyles';
import Box from '@mui/material/Box';

function Copyright(props) {
  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'By '}
        <Link color="inherit" href="https://cv.dwick.co.uk" target="_blank" rel="noreferrer">
          Alex Chadwick
        </Link>
        {', source code available '}
        <Link color="inherit" href="https://gitlab.com/alexchadwick/laterail-go" target="_blank" rel="noreferrer">
          here
        </Link>
      </Typography>
    </React.Fragment>
  );
}

export default function StickyFooter() {
  return (
    <Box
      component="footer"
      sx={{
        // borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        py: 3,
        px: 2,
        mt: 'auto',
      }}
    >
      <Container maxWidth="sm">
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </Box>
  );
}