import React from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AccordianMany from "./AccordianMany";
import Stats from "./Stats";

export default function Main(props) {
  return (
    <React.Fragment>
      <Grid 
        container 
        sx={{ px: 2 }} // Horizontal padding most evident on mobile
        rowSpacing={{
          xs: 5,
          md: 5
        }}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      >
        {/* Grid order different between mobile and larger screens */}
        <Grid item xs={0.5} md={12}></Grid> {/* spacing*/}
        <Grid item xs={12} md={2} order={{ xs: 1, md: 1 }}>
          <Box sx={{ py: 2, pl: 2, borderRadius: 2, backgroundColor: (theme) => 
              theme.palette.mode === 'dark' ? 
              theme.palette.primary.dark :
              theme.palette.primary.light
          }}>
            <Typography variant="h5" align="left" gutterBottom={true}>
              Searched
            </Typography>
            <Typography variant="body1" align="left" sx={{ pl: 4}} >
              <strong>From:</strong> {props.body.from_loc} <br/>              
              <strong>To:</strong> {props.body.to_loc} <br/>              
            </Typography>
            {props.body.from_date === props.body.to_date &&
              <Typography variant="body1" align="left" sx={{ pl: 4}} >
                <strong>Date:</strong>  {props.body.from_date}
              </Typography>
            }
            {props.body.from_date !== props.body.to_date &&
              <Typography variant="body1" align="left" sx={{ pl: 4}} >
                <strong>Dates:</strong>  {props.body.from_date} - {props.body.to_date}
              </Typography>
            }
            <Typography variant="body1" align="left" sx={{ pl: 4}} >
              <strong>Times:</strong>  {props.body.from_time} - {props.body.to_time}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}  order={{ xs: 3, md: 2 }}>
          <AccordianMany results={props.results}/>
        </Grid>
        <Grid item xs={12} md={6}  order={{ xs: 2, md: 3 }}>
          <Stats results={props.results} summary={props.summary.stats} delays={props.summary.delays}/>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}