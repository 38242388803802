import React from "react";
import Grid from '@mui/material/Grid';
import SummaryBox from "./SummaryBox";
import Pie from "./Pie";
import BoxPlot from "./BoxPlot";

export default function Stats(props) {

  return (
    <React.Fragment>
      <Grid 
        container 
        rowSpacing={{
          xs: 5,
          md: 4
        }}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Grid item xs={12} md={5}>
          <SummaryBox summary={props.summary}/>
        </Grid>
        <Grid item xs={12} md={7}>
          <Pie summary={props.summary}/>
        </Grid>
        <Grid item xs={12}>
          <BoxPlot delays={props.delays}/>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}