import React from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller } from "react-hook-form";

export default function StationSelect({ onChange: ignored, control, stationsList, filterOptions, name, rules }) {
  return (
    <Controller
      render={({ field: { onChange, ...rest }, }) => (
        <Autocomplete
          {...rest}
          id={name}
          options={stationsList}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option) => option.name}
          filterOptions={filterOptions}
          onChange={(_, newValue) => onChange(newValue)}
          renderInput={(params) => (
            <TextField 
              {...params}
              // required
              label={name}
            />
          )}

        />
      )}
      onChange={([, data]) => data}
      defaultValue={{ name: null, crs: null }}
      name={"station"+name}
      control={control}
      rules={rules}
    />
  );
}
