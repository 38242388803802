import React from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

export default function Loading(props) {

  return (
    <React.Fragment>
      <Grid 
        container
        sx={{ px: 2 }} // Horizontal padding most evident on mobile
        rowSpacing={{ xs: 5 }}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={0.5} md={12}></Grid> {/* spacing*/}
        <Grid item xs={12} md={5}>
          <Typography
            variant="h3"
            align="center"
            gutterBottom
          >
            Fetching your journeys
          </Typography>
          <LinearProgress sx={{ my: 2 }}/>
          <Box sx={{ py: 2, backgroundColor: 'info.main'}}>
            <Typography variant="body1" align="center" component="p">
              {'We need to make some requests of the '}
              <Link href="https://wiki.openraildata.com/HSP" color="inherit">
                Open Rail Data Historical Service Performance API
              </Link>
            </Typography>
            <Typography variant="body1" align="center" component="p">
              Every 6 hour period takes up to 1 minute to return, regardless of how many days are searched
            </Typography>
          </Box>
          {props.isDemo === true &&
            <Box sx={{ py: 2, my: 2, backgroundColor: 'warning.main'}}>
              <Typography variant="body1" align="center" component="p">
                THIS IS A DEMO
              </Typography>
              <Typography variant="body1" align="center" component="p">
                You will be shown some example search results after a delay of {props.demoDelay/1000} seconds
              </Typography>
            </Box>
          }
        </Grid>
      </Grid>
    </React.Fragment>
  );
}