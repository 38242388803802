import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import { matchSorter } from 'match-sorter';
import { useForm } from "react-hook-form";
import { useAuth0 } from "@auth0/auth0-react";
import StationAutoComplete from "./StationAutoComplete";
import DateTimePickerMui from "./DateTimePickerMui";
import { SetPostBody }  from "../services/ParseRequestBody.js";
import { getStations, getDemoStations } from "../services/APIClient";
import { useNavigate } from "react-router-dom";


export default function SearchJourneys() {

  const defaultValues = {
    stationTo: null,
    stationFrom: null,
    datetimeOutbound: null,
    datetimeInbound: null
  }
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const { handleSubmit, reset, control, getValues, formState: { errors } } = useForm({defaultValues});
  const [stations, setStations] = useState([]);
  const navigate = useNavigate();

  // Demo mode simulates API requests only
  const isDemo = window.config.demoMode === 'true';
  const isTest = process.env.NODE_ENV !== 'production';


  // Get stations list
  const stationsList = () => {
    if (isDemo) {
      getDemoStations()
      .then((resp) => {
        setStations(resp.data);
      })
      .catch((error) => {
        console.log(error);
      });
    } else {
      getStations()
      .then((resp) => {
        setStations(resp.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }

  // Pull the station info for each on initial load
  useEffect(() => {
    if (stations.length === 0) {
      stationsList();
    }
  });

  // Station autocomplete
  const filterOptions = (options,{ inputValue }) =>
  matchSorter(options, inputValue, {
    keys: [
      { threshold: matchSorter.rankings.STARTS_WITH, key: 'name' },
      { threshold: matchSorter.rankings.STARTS_WITH, key: 'crs' },
    ],
  });

  // Test/Demo post body
  const mockBody = {
    from_loc: "GLQ",
    to_loc: "EDB",
    from_time: "0600",
    to_time: "0930",
    from_date: "2021-11-15",
    to_date: "2021-11-15",
    tolerance: [1, 5, 10],
  };
  // const body = {
  //   from_loc: "GLQ",
  //   to_loc: "EDB",
  //   from_time: "0200",
  //   to_time: "0205",
  //   from_date: "2021-11-15",
  //   to_date: "2021-11-15",
  //   tolerance: [1, 5, 10],
  // };

  // Main search button with DEMO mode flag
  const onSubmit = data => {
    // If no react hook form errors
    if (Object.keys(errors).length === 0) {
      const body = isDemo === true ?
        mockBody :
        SetPostBody(data)
      navigate("/results", { state: { body } });
    } else {
      console.log("Errors: ", errors);
    }
  }

  // Test/Demo search button handler
  const onMockSubmit = () => {
    const body = mockBody;
    navigate("/results", { state: { body } });
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4">
          Search Journeys
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <StationAutoComplete
                control={control}
                stationsList={stations}
                filterOptions={filterOptions}
                name="From"
                rules={{
                  required: 'Required',
                  validate: {
                    SameStation: () => getValues("stationFrom") !== getValues("stationTo") || 
                      'Stations must be different',
                  }
                }}               
              />
              {errors.stationFrom && <Alert severity="error">{errors.stationFrom.message}</Alert>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <StationAutoComplete
                control={control}
                stationsList={stations}
                filterOptions={filterOptions}
                name="To"
                rules={{
                  required: 'Required',
                  validate: {
                    SameStation: () => getValues("stationFrom") !== getValues("stationTo") || 
                      'Stations must be different',
                  }
                }}               
              />
              {errors.stationTo && <Alert severity="error">{errors.stationTo.message}</Alert>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateTimePickerMui
                control={control}
                name="Outbound"
                rules={{ required: 'Required', }}
                />
              {errors.datetimeOutbound && <Alert severity="error">{errors.datetimeOutbound.message}</Alert>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateTimePickerMui
                control={control}
                name="Inbound"
                rules={{
                  required: 'Required',
                  validate: {
                    InAfterOut: () => getValues("datetimeInbound") > getValues("datetimeOutbound") || 
                      'Inbound journey must occur after Outbound',
                  }
                }}
              />
              {errors.datetimeInbound && <Alert severity="error">{errors.datetimeInbound.message}</Alert>}
            </Grid>
            <Grid item xs={12} sm={8} justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                sx={{ mr: 2}}
              >
                Search
              </Button>
              {isTest && !isDemo &&
                <Button
                  onClick={onMockSubmit}
                  variant="contained"
                  sx={{ mr: 2}}
                  color="secondary"
                >
                  TEST
                </Button>
              }
              {isDemo &&
                <Button
                  onClick={onMockSubmit}
                  variant="contained"
                  sx={{ mr: 2}}
                  color="warning"
                >
                  DEMO
                </Button>
              }
              <Button
                type="reset"
                variant="outlined"
                onClick={() => { reset()}}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
          {/* TODO: Enable after adding some authenticated user features */}
          {/* {!isAuthenticated &&
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link disabled href="#" variant="body2" onClick={() => loginWithRedirect()}>
                  Sign up to save your results
                </Link>
              </Grid>
            </Grid>
          } */}
        </Box>
      </Box>
    </React.Fragment>
  );
}