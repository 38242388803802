export function SetPostBody(formOutput) {
  const postBody = {
    from_loc: formOutput.stationFrom.crs,
    to_loc: formOutput.stationTo.crs,
    from_time: formOutput.datetimeOutbound
      .toTimeString()
      .split(" ")[0]
      .match(/\d\d:\d\d/g)[0]
      .replace(":", ""),
    to_time: formOutput.datetimeInbound
      .toTimeString()
      .split(" ")[0]
      .match(/\d\d:\d\d/g)[0]
      .replace(":", ""),
    from_date: formOutput.datetimeOutbound.toISOString().split("T")[0],
    to_date: formOutput.datetimeInbound.toISOString().split("T")[0],
    tolerance: [1, 5, 10],
  };

  return postBody;
}
