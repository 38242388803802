import React from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from "@mui/material/TableCell";
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function SummaryBox(props) {

  return (
    <React.Fragment>
      <Box sx={{ py: 2, pl: 2 }}>
        <Typography variant="h5" align="center">
          Number of Refunds
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <abbr title="Less than 30 mins late">None</abbr>
              </TableCell>
              <TableCell align="center">
                <abbr title="Less than 1 hour late">Up to 50%</abbr>
              </TableCell>
              <TableCell align="center" >
                <abbr title="More than 1 hour late or Cancelled">Up to 100%</abbr>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center" style={{borderBottom:"none"}}>
                {props.summary.lateNo}
              </TableCell>
              <TableCell align="center" style={{borderBottom:"none"}}>
                {props.summary.lateClaimPartial}
              </TableCell>
              <TableCell align="center" style={{borderBottom:"none"}}>
                {props.summary.lateClaimFull}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </React.Fragment>
  );
}