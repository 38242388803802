import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import IconButton from '@mui/material/IconButton';
import { useAuth0 } from "@auth0/auth0-react";
import { Link as RouterLink } from 'react-router-dom';

export default function Navbar(props) {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  return (
    <React.Fragment>
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        // sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Link component={RouterLink} to="/" variant="h4" color="inherit" noWrap underline="none" sx={{ flexGrow: 1 }}>
            LateRail
          </Link>
          {!isAuthenticated &&
            <Button
              disabled
              variant="outlined"
              sx={{ my: 1, mx: 1.5 }}
              onClick={() => loginWithRedirect()}
            >
              Log In
            </Button>
          }
          {isAuthenticated &&
            <Button 
              variant="outlined"
              sx={{ my: 1, mx: 1.5 }}
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Log Out
            </Button>
          }
          <IconButton sx={{ ml: 1 }} onClick={props.handleDarkModeToggle} color="inherit">
            {props.darkmode ? <LightModeOutlinedIcon/> : <DarkModeOutlinedIcon />}
          </IconButton>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}