import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { postSubmit, getDemoResponse } from "../services/APIClient";
import Loading from "../components/results/Loading";
import Main from "../components/results/Main";
import Empty from "../components/results/Empty";
import Error from "../components/results/Error";
import { 
  filterResponse,
  groupResponse,
  sortResponse,
  summaryStats } from "../services/ProcessResults";

export default function ResultsContent() {
  const location = useLocation();
  const isDemo = window.config.demoMode === 'true';
  const demoDelay = 3000;
  
  const [searchPending, setSearchPending] = useState(false);
  useEffect(() => {
    if (!searchPending) {
      setSearchPending(true);
      request(location.state.body, isDemo)
    }
  }, [searchPending, location.state.body, isDemo]);
  
  const [searchResponse, setSearchResponse] = useState(new Map([]));
  useEffect(() => {
    if (Object.keys(searchResponse).length !== 0) {
      setSearchComplete(true)
      if (searchResponse.result !== undefined) {
        // console.log("searchResponse: ", searchResponse)
        const filteredResponse = filterResponse(searchResponse, location.state.body)
        // console.log("filteredResponse: ", filteredResponse)
        const groupedResponse = groupResponse(filteredResponse, location.state.body)
        // console.log("groupedResponse: ", groupedResponse);
        const sortedResponse = sortResponse(groupedResponse)
        // console.log("sortedResponse: ", sortedResponse);
        const summary = summaryStats(groupedResponse);
        // console.log("summary", summary);
        setsummary(summary)
        setSortedResponse(sortedResponse)
      }
    }
  }, [searchResponse, location.state.body]); // Only run when searchResponse changes

  // Toggle loading and results elements
  const [searchComplete, setSearchComplete] = useState(false);
  const [sortedResponse, setSortedResponse] = useState(new Map([]));
  const [summary, setsummary] = useState({});

  // Demo mode simulates API requests only
  function request(body, isDemo) {
    if (isDemo === true) {
      setTimeout(
        () =>
          getDemoResponse()
            .then((resp) => {
              setSearchResponse(resp.data);
            })
            .catch((error) => {
              console.log(error);
            }),
        demoDelay
      );
    } else {
      postSubmit(body)
        .then((resp) => {
          setSearchResponse(resp.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  // Check response for errors or empty response (no matching services)
  const [error, setError] = useState(false);
//   useEffect(() => {
//     if (searchResponse.result !== undefined) {
//       console.log("Returned", searchResponse.result.length, "services")
//     } else if (searchResponse.info !== undefined) { 
//       console.log("No services returned")
//     } else if (searchResponse.info !== undefined) { 
//     setError(true)
//     console.log("API Error response")
//   }
// }, [searchResponse]);

  return (
    <React.Fragment>
      {/* Loading screen */}
      {searchComplete !== true &&
        <Loading isDemo={isDemo} demoDelay={demoDelay} />
      }
      {/* Show results */}
      {searchComplete === true && searchResponse.result !== undefined && error === false &&
        <Main 
          body={location.state.body}
          results={sortedResponse}
          summary={summary}
        />
      }
      {/* No services returned */}
      {searchComplete === true && searchResponse.info !== undefined && error === false &&
        <Empty />
      }
      {/* TODO: Error processing request */}
      {error === true &&
        <Error />
      }
      </React.Fragment>
  );
}
