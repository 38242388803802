import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SearchJourney from '../components/SearchJourney';
import { useAuth0 } from "@auth0/auth0-react";

function HomeContent() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const isDemo = window.config.demoMode === 'true' || false;

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <React.Fragment>
      <Grid 
        container 
        sx={{ px: 2 }} // Horizontal padding most evident on mobile
        rowSpacing={{
          xs: 5,
          md: 20
        }}
      >
        {isDemo === true &&
          <Grid item xs={12}>
            <Box sx={{ py: 2, my: 2, backgroundColor: 'warning.main'}}>
              <Typography variant="body1" align="center" component="p">
                THIS IS A DEMO
              </Typography>
              <Typography variant="body1" align="center" component="p">
                You'll be shown some example results, regardless of what you search
              </Typography>
              <Typography variant="body1" align="center" component="p">
                Use the DEMO button to skip ahead to the results
              </Typography>
            </Box>
          </Grid>
        }
        {isDemo === false &&
          <Grid item md={12}></Grid>
        }
        <Grid item md={1}></Grid>
        <Grid
          item
          xs={12}
          md={5}
        >
          <Typography
            variant="h3"
            align="center"
            gutterBottom
          >
            Claim back money
          </Typography>
          <Typography variant="body1" align="center" color="text.secondary" component="p">
            Check if you're eligble for refunds on UK rail journeys because of delay or cancellation
          </Typography>
          {isAuthenticated &&
            <div>
              <img src={user.picture} alt={user.name} />
              <h2>{user.name}</h2>
              <p>{user.email}</p>
            </div>
          }
        </Grid>
        <Grid item md={0.5}></Grid>
        <Grid item xs={12} md>
          <SearchJourney/>
        </Grid>
        <Grid item xs={1}></Grid> {/*Column padding on md, row padding below */}
        <Grid item md={12}></Grid> {/*Row padding relative to screen size, using spacing */}
      </Grid>
    </React.Fragment>
  );
}

export default function Home() {
  return <HomeContent />;
}
