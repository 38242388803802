import React from "react";
import AccordionSingle from "./AccordianSingle";

// Loop over a map and extract map child property as value
export default function AccordianMany(props) {
  return (
    <React.Fragment>
      <ul>
        {
          [...props.results.entries()].map((value) => {
            return <AccordionSingle value={value} key={value[0]}/>
          })
        }
      </ul>
    </React.Fragment>
  );
}