import React from "react";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { Controller } from "react-hook-form";

export default function DateTimeSelect({ onChange: ignored, control, name, rules }) {
  return (
    <Controller
    render={({ field: { onChange, ref, value, ...rest  }, }) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          {...rest}
          renderInput={(props) => (
            <TextField
              // required
              fullWidth
              {...props}
            />
          )}          
          label={name}
          disableFuture
          value={value}
          inputRef={ref}
          inputFormat="dd/MM/yyyy hh:mm a"
          onChange={(newValue) => onChange(newValue)}
        />
      </LocalizationProvider>
    )}
    onChange={([, data]) => data}
    defaultValue={null}
    name={"datetime"+name}
    control={control}
    rules={rules}
  />
  );
}
