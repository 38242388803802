import React from "react";
import { useTheme } from '@mui/material/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";

// Register the required components
echarts.use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

// import ReactECharts from 'echarts-for-react';

export default function Pie(props) {
  const theme = useTheme();
  const backgroundColor = theme.palette.mode === 'dark' ? 'none' : '#ffffff'

  const option = {
    lazyUpdate: true,
    backgroundColor: backgroundColor,
    title: {
      text: "Delay (mins) and\nCancellations",
      left: "center",
    },
    tooltip: {
      trigger: "item",
      formatter: "{c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: ["On Time", "1 - 30", "30 - 60", "60+ or\nCancelled"],
    },
    series : [
      {
        name: "Delay",
        type: "pie",
        radius: ["40%", "70%"],
        center: ["50%", "55%"],
        data:[
          {value: props.summary.onTime, name: "On Time" },
          {value: props.summary.lateNo, name: "1 - 30" },
          {value: props.summary.lateClaimPartial, name: "30 - 60" },
          {value: props.summary.lateClaimFull, name: "60+ or\nCancelled" },
        ],
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
          label: {
            show: true,
            fontSize: "20",
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
      }
    ]
  };

  return (
    <React.Fragment>
      <ReactEChartsCore
        echarts={echarts}
        option={option}
        style={{ height: 300 }}
        theme={theme.palette.mode}
      />
    </React.Fragment>
  );
}