import React from "react";
import { useTheme } from '@mui/material/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BoxplotChart, ScatterChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import {
  DatasetComponent,
  TitleComponent,
  GridComponent,
  TransformComponent,
} from "echarts/components";

// Register the required components
echarts.use([
  BoxplotChart,
  CanvasRenderer,
  DatasetComponent,
  GridComponent,
  ScatterChart,
  TitleComponent,
  TransformComponent,
]);

export default function BoxPlot(props) {
  const theme = useTheme();
  const backgroundColor = theme.palette.mode === 'dark' ? 'none' : '#ffffff'

  const option = {
    lazyUpdate: true,
    backgroundColor: backgroundColor,
    title: [
      {
        text: "Boxplot - Delay (mins)",
        left: "center",
      },
      {
        text: "Upper: Q3 + 1.5 * IRQ \nLower: Q1 - 1.5 * IRQ",
        borderColor: "#999",
        // borderWidth: 1,
        textStyle: {
          fontSize: 14,
        },
        left: "5%",
        top: "10%",
      },
    ],
    dataset: [
      {
        source: [
          props.delays
        ]
      },
      {
        transform: {
          type: "boxplot",
          config: {
            itemNameFormatter: function (params) {
              return params;
            },
          },
        },
      },
      {
        fromDatasetIndex: 1,
        fromTransformResult: 1,
      },
    ],
    grid: {
      left: "10%",
      right: "10%",
      bottom: "15%",
    },
    yAxis: {
      type: "category",
      show: false,
    },
    xAxis: {
      type: "value",
      splitArea: {
        show: true,
      },
    },
    series: [
      {
        name: "boxplot",
        type: "boxplot",
        datasetIndex: 1,
        itemStyle: {
          color: "#bff8ef",
          borderColor: "#00d1b2",
          borderType: "solid",
          borderWidth: 2,
        },
      },
      {
        name: "outlier",
        type: "scatter",
        encode: { x: 1, y: 0 },
        datasetIndex: 2,
        itemStyle: {
          color: "#bff8ef",
          borderColor: "#00d1b2",
          borderType: "solid",
          borderWidth: 2,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <ReactEChartsCore
        echarts={echarts}
        option={option}
        // style={{ height: 300 }}
        theme={theme.palette.mode}
      />
    </React.Fragment>
  );
}