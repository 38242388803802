import React from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

export default function Loading(props) {

  return (
    <React.Fragment>
      <Grid 
        container
        sx={{ px: 2 }} // Horizontal padding most evident on mobile
        rowSpacing={{ xs: 5 }}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={0.5} md={12}></Grid> {/* spacing*/}
        <Grid item xs={12} md={5}>
          <Typography
            variant="h3"
            align="center"
            gutterBottom
          >
            No matching journeys
          </Typography>
          <Box sx={{ py: 2, backgroundColor: 'warning.main'}}>
            <Typography variant="body1" align="center" component="p">
              {'An error has occured with either LateRail, the '}
              <Link href="https://wiki.openraildata.com/HSP" color="inherit">
              Open Rail Data Historical Service Performance API
              </Link>
            </Typography>
            <Typography variant="body1" align="center" component="p">
              Or your journey includes a service change. LateRail is only able to return results for direct journeys
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}