import React, { useState, useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTheme } from '@mui/material/styles';


export default function AccordionSingle(props) {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (props.value[1].get("to").delay.Int32 > 0 || props.value[1].get("cancelled").bool) {
      setExpanded(props.value[0]);
    }
  }, [props.value]);
  
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // Set accordian theme incl. pallette mode (dark/light)
  function statusTheme(theme, value) {
    if (theme.palette.mode === 'dark') {
      if (value[1].get("to").delay.Int32 > 0 && value[1].get("to").delay.Int32 < 30) {
        // setExpanded(value[0])
        return theme.palette.warning.dark
      } else if (value[1].get("to").delay.Int32 >= 30 || value[1].get("cancelled").bool) {
        // setExpanded(value[0])
        return theme.palette.error.dark
      } else {
        return theme.palette.primary.dark
      }
    } else if (theme.palette.mode === 'light') {
      if (value[1].get("to").delay.Int32 > 0 && value[1].get("to").delay.Int32 < 30) {
        return theme.palette.warning.light
      } else if (value[1].get("to").delay.Int32 >= 30 || value[1].get("cancelled").bool) {
        return theme.palette.error.light
      } else {
        return theme.palette.primary.light
      }
    }
  }

  return (
    <React.Fragment>
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={expanded === props.value[0]}
        onChange={handleChange(props.value[0])}
        disableGutters={true}
        sx={{ borderRadius: 1 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={props.value[0]}
          id={props.value[0]}
          sx={{
            backgroundColor: statusTheme(theme, props.value),
            borderRadius: 1
          }}
        >
          <Typography variant="body1" style={{ fontWeight: 600 }}>
        {
          new Date(props.value[1].get("from").sched_depart.Time).toLocaleDateString(
            "en-GB"
          )
        }
        {' '}
        {
          new Date(props.value[1].get("from").sched_depart.Time).toLocaleTimeString(
            "en-GB",
            { hour: "2-digit", minute: "2-digit" }
          )
        }
        {' - '}
        {
          new Date(props.value[1].get("to").sched_arrival.Time).toLocaleTimeString(
            "en-GB",
            { hour: "2-digit", minute: "2-digit" }
          )
        }
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Station</TableCell>
                  <TableCell>Scheduled</TableCell>
                  { props.value[1].get("cancelled").bool === false &&
                    <>
                      <TableCell>Actual</TableCell>
                      <TableCell>Delay</TableCell>
                    </>
                  }
                  { props.value[1].get("cancelled").bool === true &&
                    <TableCell>Cancelled</TableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {/* Departure */}
                  <TableCell>{ props.value[1].get("from").station }</TableCell>
                  <TableCell>
                    {
                      new Date(
                        props.value[1].get("from").sched_depart.Time
                      ).toLocaleTimeString("en-GB", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    }
                  </TableCell>
                  { props.value[1].get("cancelled").bool === false &&
                    <>
                      <TableCell>
                        {
                          new Date(
                            props.value[1].get("from").actual_depart.Time
                          ).toLocaleTimeString("en-GB", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        }
                      </TableCell>
                      <TableCell>{ props.value[1].get("from").delay.Int32 }</TableCell>
                    </>
                  }
                  { props.value[1].get("cancelled").bool === true &&
                    <>
                      { props.value[1].get("cancelled").reason > 0 &&
                        <TableCell>
                          Late cancel reason: { props.value[1].get("cancelled").reason }
                        </TableCell>
                      }
                      { props.value[1].get("cancelled").reason === 0 &&
                        <TableCell>
                          No cancellation reason provided
                        </TableCell>
                      }
                    </>
                  }
                </TableRow>
                <TableRow>
                  {/* Arrival */}
                  <TableCell>{ props.value[1].get("to").station }</TableCell>
                  <TableCell>
                    {
                      new Date(
                        props.value[1].get("to").sched_arrival.Time
                      ).toLocaleTimeString("en-GB", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    }
                  </TableCell>
                  { props.value[1].get("cancelled").bool === false &&
                    <>
                      <TableCell>
                        {
                          new Date(
                            props.value[1].get("to").actual_arrival.Time
                          ).toLocaleTimeString("en-GB", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        }
                      </TableCell>
                      <TableCell>{ props.value[1].get("to").delay.Int32 }</TableCell>
                    </>
                  }
                </TableRow>
              </TableBody>
            </Table>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}